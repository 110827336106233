// src/components/Layout.tsx
import React, { ReactNode, useEffect, useState } from 'react';
import { Container, Typography, AppBar, Toolbar, Button, Modal, Box, TextField, Stack, Link, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, Grid } from '@mui/material';
import { styled } from '@mui/system';
import { Login } from '../services/ApiEndpoints';
import { useLocation } from 'react-router-dom';
import BaseApiServices from '../services/BaseApiServices';
import { getLocalStorage, setLocalStorage } from '../helpers/tools';
import "../App.css"

interface LayoutProps {
    children: ReactNode;
}

const StyledContainer = styled(Container)(({ theme }) => ({
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(10),
}));

const StyledAppBar = styled(AppBar)({
    marginBottom: '20px',
});

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    justifyContent: 'space-between',
}));

const Layout: React.FC<LayoutProps> = ({ children }) => {
    const [isLogedIn, setIsLogedIn] = useState<boolean>(false)
    const [openModalLogin, setOpenModalLogin] = useState<boolean>(false)
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [userInfo, setUserInfo] = useState<any>({});

    const handleInputChange = (event: any) => {
        const { name, value } = event.target;
        if (name === 'username') {
            setUsername(value);
        } else if (name === 'password') {
            setPassword(value);
        }
    };

    const handleLogin = (event: any) => {
        event.preventDefault();
        // Implement login logic here
        // Close the modal
        const payload: any = {
            username: username,
            password: password
        }
        Login(payload).then((response: any) => {
            // notiSuccess('เข้าสู่ระบบสำเร็จ')
            clearForm()
            BaseApiServices.setAuthToken(response?.data?.token)
            setLocalStorage('token',response?.data?.token)
            setLocalStorage('user',response?.data?.user)
            setUserInfo(response?.data?.user || {})
            setIsLogedIn(true)
            // AxiosService.setAuthToken(response.token)
            // dispatch(setUserInfo({ ...response.user, is_login: true }));
            // setLocalStorage('token',response.token)
            // AxiosService.setAuthToken(response.)
            // setIsLoading(false)
            // onClose()
            setOpenModalLogin(false);
        }).catch((error: any) => {
            // notiError('มีบางอย่างผิดพลาด')
            // dispatch(setUserInfo(null));
            // setIsLoading(false)
            window.sessionStorage.clear()
            clearForm()
            setIsLogedIn(false)
            // setOpenModalLogin(false);
        })

        // setOpenModalLogin(false);
    };
    const clearForm = () => {
        setUsername('')
        setPassword('')
    }
    const handleLogout = () => {

    }
    const location = useLocation();
    const isActive = (path: string) => location.pathname == path;

    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    useEffect(() => {
      let token = getLocalStorage('token')
      if (token) {
        BaseApiServices.setAuthToken(token)
      }
      let user = getLocalStorage('user')
      if(user){
        setUserInfo(user)
      }
      if (token && user) {
        setIsLogedIn(true)
      }
    }, [])
    
    return (
        <div>
            <StyledAppBar position="static" sx={{ backgroundColor: '#123456', color: '#ffffff' }}>
                <StyledToolbar sx={{ justifyContent: 'space-between', marginLeft:8, marginRight:8}}>
                    <Typography variant="body1" sx={{ flexGrow: 1, userSelect: 'none' }}>
                        {/* <Grid container>
                            <Grid item xs={12}>
                            <div style={{ fontSize: '20px', fontWeight: 'bold' }}>USDT</div>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <div style={{ fontSize: '16px' }} className='text-purple fbd'>WETRUST</div>
                            <div style={{ fontSize: '14px' }} className='text-green fb'>FAST TRACK GATEWAY TO BUY USDT !</div>
                        </Grid> */}
                        {/* <div style={{ fontSize: '20px', fontWeight: 'bold' }}>USDT</div> */}
                        {/* <div style={{ textAlign: 'left' }}>
                            
                        </div> */}
                        <div style={{display:'flex'}}>
                            <div style={{ fontSize: '35px', fontWeight: 'bold' }}>USDT</div>
                            <div>
                                <div style={{ fontSize: '16px' }} className='text-purple fbd'>WETRUST</div>
                                <div style={{ fontSize: '14px' }} className='text-green fb'>FAST TRACK GATEWAY TO BUY USDT !</div>
                            </div>
                        </div>
                    </Typography>
                    <Box sx={{ display: 'flex', gap: '16px' }}>
                        <a href="/" className='header-menu'>
                            <div style={{ fontSize: '14px' }}>EXCHANGES RATE</div>
                            <div style={{ fontSize: '10px' }} className='text-green' >อัตราการแลกเปลี่ยน</div>
                        </a>
                        <a href="/buycrypto" className='header-menu'>
                            <div style={{ fontSize: '14px' }}>BUY USDT</div>
                            <div style={{ fontSize: '10px' }} className='text-green' >ซื้อ USDT</div>
                        </a>
                        <a href="/contact" className='header-menu'>
                            <div style={{ fontSize: '14px' }}>CUSTOMER SUPPORT</div>
                            <div style={{ fontSize: '10px' }} className='text-green' >ติดต่อฝ่ายสนับสนุน</div>
                        </a>
                        <div  className='header-menu' onClick={() => !isLogedIn ? setOpenModalLogin(true) : handleLogout()}>
                            <div style={{ fontSize: '14px' }}>{isLogedIn ? `${userInfo?.username}` : 'LOGIN'}</div>
                            <div style={{ fontSize: '10px' }} className='text-green' >{isLogedIn ? `ออกจากระบบ` : 'เข้าสู่ระบบ'}</div>
                        </div>
                        {/* <Button color="inherit" href="/" sx={{ ...(isActive('/') && { backgroundColor: '#f5f5f5', color: '#123456' }), '&:hover': { backgroundColor: '#f5f5f5', color: '#123456' }, transition: 'all 0.3s ease' }}>exchanges</Button>

                        <Button color="inherit" href="/buycrypto" sx={{ ...(isActive('/buycrypto') && { backgroundColor: '#f5f5f5', color: '#123456' }), '&:hover': { backgroundColor: '#f5f5f5', color: '#123456' }, transition: 'all 0.3s ease' }}>buy USDT</Button>

                        <Button color="inherit" href="/contact" sx={{ ...(isActive('/contact') && { backgroundColor: '#f5f5f5', color: '#123456' }), '&:hover': { backgroundColor: '#f5f5f5', color: '#123456' }, transition: 'all 0.3s ease' }}>contact us</Button>

                        <Button color="inherit" onClick={() => !isLogedIn ? setOpenModalLogin(true) : handleLogout()} sx={{ ...(isActive('/login') && { backgroundColor: '#f5f5f5', color: '#123456' }), '&:hover': { backgroundColor: '#f5f5f5', color: '#123456' }, transition: 'all 0.3s ease' }}>
                            {isLogedIn ? `${userInfo?.username}` : 'Login'}
                        </Button> */}
                    </Box>
                </StyledToolbar>
            </StyledAppBar>

            <StyledContainer>
                {children}
            </StyledContainer>
            <footer
            >
                <Box
                    sx={{
                        position: 'fixed',
                        bottom: 0,
                        width: '100%',
                        backgroundColor: '#f8f8f8',
                        textAlign: 'center',
                        padding: '10px',
                        marginTop: '50px'

                    }}
                >
                    <div style={{display: 'flex',justifyContent:'center', alignItems:'center'}}>
                        <Typography variant="body2" className='text-green'>
                            &copy; {new Date().getFullYear()} WETRUST สงวนลิขสิทธิ์
                        </Typography>
                        {' | '}
                        <Typography variant="body2" className='text-purple'>
                            <Link href="#" onClick={handleOpen} color="inherit" underline="hover">
                                ข้อกฏหมายและเงื่อนไขการให้บริการ
                            </Link>
                        </Typography>
                    </div>
                    

                    {/* Terms and Policies Dialog */}
                    <Dialog open={open} onClose={handleClose}>
                        <DialogTitle>Terms and Policies</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                The following Terms and Policies apply to the use of wetrustfinance.com, which is provided by We Trust Group Ltd, Co. By using our website, you agree to comply with these terms. Please read them carefully.

                                <br /><br />
                                **Privacy Policy:** We are committed to protecting your privacy. Your personal information will be handled in accordance with our privacy policy.

                                <br /><br />
                                **Use of Services:** You agree to use our services ethically and within the boundaries of the law. Any misuse of services will lead to appropriate action.

                                <br /><br />
                                **Content:** All content provided on wetrustfinance.com is for informational purposes only. We Trust Group Ltd, Co. assumes no responsibility for errors or omissions in the contents.

                                <br /><br />
                                For full details of our Terms and Policies, please visit our website or contact us directly.

                                <br /><br />
                                If you have any questions or concerns about these terms, please contact us at support@wetrustfinance.com.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>Close</Button>
                        </DialogActions>
                    </Dialog>

                </Box>
            </footer>
            <Modal
                open={openModalLogin}
                onClose={() => setOpenModalLogin(false)}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: { xs: '90%', sm: 400 }, // Responsive width
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: 2, // Added rounded corners
                    }}
                >
                    <Typography id="login-modal-title" variant="h6" component="h2" sx={{ fontWeight: 'medium', textAlign: 'center', mb: 3 }}>
                        Login
                    </Typography>
                    <Box
                        component="form"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            mt: 2,
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label="username"
                            name="username"
                            autoFocus
                            value={username}
                            onChange={handleInputChange}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="password"
                            type="password"
                            id="password"
                            value={password}
                            onChange={handleInputChange}
                        />
                        <Stack direction="row" spacing={2} sx={{ mt: 3, justifyContent: 'center' }}>
                            <Button
                                type="button"
                                variant="outlined"
                                sx={{ py: 1.5, width: '100px', borderColor: 'primary.main', color: 'primary.main', '&:hover': { borderColor: 'primary.dark', color: 'primary.dark' } }}
                                onClick={() => setOpenModalLogin(false)}
                            >
                                Cacel
                            </Button>
                            <Button
                                type="submit"
                                variant="contained"
                                sx={{ py: 1.5, width: '100px', bgcolor: 'primary.main', '&:hover': { bgcolor: 'primary.dark' } }}
                                onClick={handleLogin}
                            >
                                Login
                            </Button>
                        </Stack>
                    </Box>
                </Box>

            </Modal>
        </div>
    );
};

export default Layout;
