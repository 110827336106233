import React, { useState } from 'react';
import { Box, Button, Container, TextField, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Card } from '@mui/material';

const ContactUs: React.FC = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });
    const [openDialog, setOpenDialog] = useState(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        // Simulate form submission
        // Open the thank you dialog
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setFormData({
            name: '',
            email: '',
            message: '',
        })
        setOpenDialog(false);
        // Optionally reset form or redirect user
    };

    return (
        <Card sx={{
            width: '820px', mx: 'auto', // Centers the card with margin auto
            overflow: 'hidden',
            borderRadius: '12px',
            boxShadow: '0 4px 24px rgba(0,0,0,0.12)',
            p: 3, // Padding inside the card
        }}>
            <Container component="main" maxWidth="sm">
                <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography component="h1" variant="h5">
                        Contact Us
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="name"
                            label="Name"
                            name="name"
                            autoComplete="name"
                            autoFocus
                            value={formData.name}
                            onChange={handleChange}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            value={formData.email}
                            onChange={handleChange}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="message"
                            label="Message"
                            type="text"
                            id="message"
                            multiline
                            rows={4}
                            value={formData.message}
                            onChange={handleChange}
                        />
                        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                            Send Message
                        </Button>
                    </Box>
                </Box>
                <Dialog open={openDialog} onClose={handleCloseDialog}>
                    <DialogTitle>Thank You!</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Your message has been sent successfully. We appreciate your feedback and will get back to you as soon as possible.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog}>OK</Button>
                    </DialogActions>
                </Dialog>
            </Container>
        </Card>
    );
};

export default ContactUs;
