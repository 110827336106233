import { ReactNode } from "react";

interface buttonProps {
  children?: ReactNode;
  label?: string;
  onClick?: () => any;
  type?: "button" | "submit" | "reset";
  className?: string;
  disabled?: boolean;
}
const WeButton: React.FC<buttonProps> = ({
  children,
  label,
  onClick,
  type = "button",
  className,
  disabled = false,
}) => {
    const buttonStyle = {
        backgroundColor: 'white',
        color: '#00BF63',
        minWidth: '160px',
        minHeight: '40px',
        fontWeight: 'bold' as 'bold',
        textTransform: 'uppercase' as 'uppercase',
        padding: '10px',
        boxShadow: '0 0 0 5px #C0C0C0, 0 0 0 10px #123456',
        cursor: 'pointer',
        margin: '10px',
        border: 'none'
      };
    
      const disabledStyle = {
        backgroundColor: '#ccc',
        color: '#666',
        borderColor: '#999',
        boxShadow: '0 0 0 5px #eee, 0 0 0 10px #999',
        cursor: 'not-allowed',
      };
  return (
    <button
      type={type}
      className={''}
      onClick={onClick}
      disabled={disabled}
      style={disabled ? {...buttonStyle, ...disabledStyle} : buttonStyle}
    >
      {children ? children : label}
    </button>
  );
};

export default WeButton;
