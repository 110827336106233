import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

class BaseApiService {
  private apiKey = "5c1b24f268212bde99ea5103";
  private instance = axios.create({
    baseURL: "https://wetrust.concrete-today.com/api/",
    // Add other configuration options as needed
  });

  private async request<T>(
    method: string,
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    try {
      const response = await this.instance.request<T>({
        method,
        url,
        data,
        ...config,
      });
      return response;
    } catch (error) {
      throw error;
    }
  }

  get<T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.request<T>("GET", url, undefined, config);
  }

  post<T>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    return this.request<T>("POST", url, data, config);
  }

  put<T>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    return this.request<T>("PUT", url, data, config);
  }

  delete<T>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    return this.request<T>("DELETE", url, undefined, config);
  }

  // You can add more methods as needed for your API

  // Example: Patch request
  patch<T>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    return this.request<T>("PATCH", url, data, config);
  }
  setAuthToken(token: string) {
    this.instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }

  // Clear authentication token
  clearAuthToken() {
    delete this.instance.defaults.headers.common["Authorization"];
  }
}

export default new BaseApiService();
