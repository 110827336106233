import BaseApiServices from "./BaseApiServices";

export const UploadFile = async (file: any, name: string | null) => {
  const formData = new FormData();
  formData.append("file", file);
  if (name) {
    formData.append("name", name);
  }
  return BaseApiServices.post("file/upload", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
export const GetAccountList = async (master_id: number | string) => {
  return BaseApiServices.get(`account/${master_id}/item`);
};
export const Login = async (payload: any) => {
  return BaseApiServices.post("authentication/login", payload);
};
export const GetInfo = async () => {
  return BaseApiServices.get("authentication/info");
};
export const AddTransaction = async (payload: any) => {
  return BaseApiServices.post("transaction", payload);
};
export const GetTransaction = async (payload: any) => {
  return BaseApiServices.get("transaction", payload);
};
