import React, { useEffect, useState } from 'react'
import Loading from '../components/Loading'
import { Box, Button, ButtonGroup, Card, CardContent, CardHeader, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, InputAdornment, InputLabel, ListItemIcon, MenuItem, Select, TextField, Typography } from '@mui/material'
import Iconify from '../helpers/Iconify'
import { AddTransaction, GetAccountList, UploadFile } from '../services/ApiEndpoints'
import { formatCurrency, removeLocalStorage } from '../helpers/tools'
import { toast } from 'react-toastify'

const currencyData = [
    {
        "currency": "USDT",
        "name": "Tether(ETH)",
        "region": "North America",
        "country": "United States",
        "flag": "cryptocurrency-color:usdt",
        "rate": 1
    },
    {
        "currency": "THB",
        "name": "Thai Baht",
        "region": "Asia",
        "country": "Thailand",
        "flag": "emojione-v1:flag-for-thailand",
        "rate": 0.3824
    },
]
const BuyCrypto = () => {
    const [loading, setLoading] = useState<boolean>(false)
    const [isCheckout, setIsCheckout] = useState<boolean>(false)
    const [selectedAccount, setSetselectedAccount] = useState<any | null>(null)
    const [accountList, setAccountList] = useState<any[]>([])
    const [file, setFile] = useState<any | null>(null)
    const [formData, setFormData] = useState<any>({
        toCurrency: 'USDT',
        fromCurrency: 'THB',
        sendAmount: '',
        getAmount: '',
        bankName: '',
        accountNo: '',
        accountName: '',
        wallet:'',
        payTime: ''
    })
    const renderCurrencyOptions = () => {
        return currencyData.map((currency: any) => (
            <MenuItem
                key={currency.currency} value={currency.currency}
                disabled={formData.fromCurrency == currency.currency || formData.toCurrency == currency.currency}
            >
                <ListItemIcon>
                    <Iconify icon={currency.flag} />
                </ListItemIcon>
                {currency.currency} - {currency.name}
            </MenuItem>
        ));
    };
    const fetchAccounts = (master_id?: number) => {
        setLoading(true)
        GetAccountList(master_id || 1).then((response: any) => {
            setAccountList(response.data || [])
            setLoading(false)
        }).catch(err => {
            setAccountList([])
            setLoading(false)
        })
    }
    const handleChange = (e: any) => {
        const target = e.target as HTMLInputElement;
        setFormData({ ...formData, [target.name || '']: target.value });
        if (target.name == 'toCurrency' || target.name == 'fromCurrency') {
        } else {
        }
    };
    const calculator = () => {
        const rate = currencyData.find((c: any) => c.currency === formData.fromCurrency)?.rate || 1
        const base = formData.sendAmount || 1
        const result = base / (rate * 100)
        setFormData({ ...formData, getAmount: result });
    }
    const checkout = () => {
        if (!formData.wallet || !formData.sendAmount) {
            setIsDialogOpen(true)
            return;
        }
        setLoading(true)
        setTimeout(() => {
            setIsCheckout(true)
            setLoading(false)
        }, 1500);
    }
    const handleSelectAccount = (account: any) => {
        setLoading(true)
        setTimeout(() => {
            setSetselectedAccount(account)
            setLoading(false)
        }, 2000);
    }
    const handleFileChange = (e: any) => {
        const file = e.target?.files[0]
        setLoading(true)
        UploadFile(file, null).then((response: any) => {
            setFile(response.data)
            // notiSuccess(`อัพโหลดรูปภาพสำเร็จ`)
            setLoading(false)
        }).catch((error: any) => {
            
            console.log('error',error);
            if (error?.response?.status == 401) {
                removeLocalStorage('user')
                removeLocalStorage('token')
                toast.error("Please login first.");
                setTimeout(() => {
                    window.location.reload()
                }, 1500);
            }else{
                toast.error("Something wrong.");
                setFile(null)
            }
            
            setLoading(false)
        })
    }
    const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);

    const handleSubmit = () => {
        if (!formData.bankName || !formData.accountNo || !formData.accountName) {
            setIsDialogOpen(true)
            return;
        }
        setLoading(true)
        const payload: any = {
            account_id: selectedAccount?.id,
            amount: formData?.sendAmount,
            currency: formData?.fromCurrency,
            received_point: formData?.getAmount,
            name: formData?.accountName,
            category_id: 1,
            upload_id: file?.id,
            transaction_time: formData?.transactionTime || new Date(),
            target_currency : formData?.toCurrency,
            target_wallet: formData?.wallet,
            account_name: formData?.accountName,
            account_no: formData?.accountNo,
            bank_name: formData?.bankName,
        }
        AddTransaction(payload).then((response: any) => {
            // onSuccess()
            // notiSuccess('ทำรายการสำเร็จ')
            setIsSuccessDialogOpen(true);
            setLoading(false)
        }).catch((error: any) => {
            // notiError('เกิดข้อผิดพลาด')
            setLoading(false)
        })

    }
    useEffect(() => {
        if (formData.sendAmount) {
            calculator()
        }
    }, [formData.sendAmount])

    useEffect(() => {

        fetchAccounts()
    }, [])
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
    };

    return (
        <div>
            <Loading loading={loading} />
            <Typography sx={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                <Card sx={{
                    width: '820px', mx: 'auto', // Centers the card with margin auto
                    overflow: 'hidden',
                    borderRadius: '12px',
                    boxShadow: '0 4px 24px rgba(0,0,0,0.12)',
                    p: 3, // Padding inside the card
                }}>
                    <CardContent>
                        <Typography component='div' variant='h6' paddingBottom={2} sx={{ fontWeight: 'bold', color: 'text.primary' }}>
                            Please fill in transaction details
                        </Typography>
                        <Typography component='div' variant='h5' sx={{ width: '100%' }}>
                            <ButtonGroup size='large' aria-label="Basic button group" fullWidth sx={{ '.MuiButtonGroup-grouped': { flexGrow: 1, borderRadius: '8px', borderColor: 'primary.main' }, '& .MuiButton-contained': { color: 'white', backgroundColor: 'primary.main', '&:hover': { backgroundColor: 'primary.dark' } } }}>
                                <Button sx={{ justifyContent: "center" }}>P2P</Button>
                                <Button disabled sx={{ justifyContent: "center" }}>Express</Button>
                                <Button disabled sx={{ justifyContent: "center" }}>Block Trade</Button>
                            </ButtonGroup>
                        </Typography>
                        <Box display={!!!selectedAccount ? '' : 'none'} marginTop={2}>
                            <Typography component={'div'} variant='h6' >please select seller</Typography>
                            <Box sx={{ padding: 2 }}>
                                {
                                    accountList.map((account: any, index: number) =>
                                        <Typography sx={{ justifyContent: 'space-between', display: 'flex', paddingY: 1, borderBottom: 1, borderColor: 'divider' }}>
                                            <Typography sx={{ display: 'flex', alignItems: 'center', color: 'text.secondary', gap: 1 }}>
                                                ({index + 1}) {account.name} {account.no}
                                                {account.is_active && <Iconify icon='gravity-ui:seal-check' sx={{ color: 'success.main' }} />}
                                            </Typography>
                                            <Button
                                                variant="outlined"
                                                sx={{
                                                    color: 'primary.main',
                                                    borderColor: 'primary.light',
                                                    '&:hover': {
                                                        backgroundColor: 'primary.light',
                                                        borderColor: 'primary.main',
                                                    },
                                                    '&:disabled': {
                                                        color: 'action.disabled',
                                                        borderColor: 'action.disabledBackground',
                                                    }
                                                }}
                                                disabled={selectedAccount?.id === account.id}
                                                onClick={() => handleSelectAccount(account)}
                                            >
                                                Buy
                                            </Button>
                                        </Typography>

                                    )
                                }
                            </Box>
                        </Box>

                        <Box display={!!selectedAccount ? '' : 'none'} sx={{ paddingTop: 3 }}>

                            <Grid container spacing={2}>
                                <Grid item xs={7}>
                                    <InputLabel htmlFor="sendAmount">You Pay</InputLabel>
                                    <TextField
                                        type="number"
                                        fullWidth
                                        name="sendAmount"
                                        variant="outlined"
                                        placeholder='0.00'
                                        value={formData.sendAmount}
                                        onChange={handleChange}
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                fontWeight: '400', // Regular font weight for input text
                                            },
                                            '& .MuiOutlinedInput-root': {
                                                '&:hover fieldset': {
                                                    borderColor: 'primary.main', // Highlight on hover
                                                },
                                            },
                                        }}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">{formData.fromCurrency || ''}</InputAdornment>,
                                        }}
                                        disabled={isCheckout}
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <InputLabel htmlFor="fromCurrency">From Currency</InputLabel>
                                    <Select
                                        fullWidth
                                        label="Currency"
                                        name="fromCurrency"
                                        value={formData.fromCurrency}
                                        onChange={(event: any) => handleChange(event)}
                                        variant="outlined"
                                        disabled={isCheckout}
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                fontWeight: '400', // Regular font weight for input text
                                            },
                                            '& .MuiOutlinedInput-root': {
                                                '&:hover fieldset': {
                                                    borderColor: 'primary.main', // Highlight on hover
                                                },
                                            },
                                        }}
                                    >
                                        {renderCurrencyOptions()}
                                    </Select>
                                </Grid>
                                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography>
                                        Estimated rate : 1 USDT ~ {(currencyData.find((c: any) => c.currency == formData.fromCurrency)?.rate || 1) * 100} {currencyData.find((c: any) => c.currency == formData.fromCurrency)?.currency}
                                    </Typography>
                                    <IconButton
                                        // onClick={() => onSwith()}
                                        disabled
                                    >
                                        <Iconify size='30px' icon="heroicons-outline:switch-vertical" />
                                    </IconButton>
                                </Grid>
                                <Grid item xs={7}>
                                    <InputLabel htmlFor="getAmount">You Get</InputLabel>
                                    <TextField
                                        type="number"
                                        fullWidth
                                        name="getAmount"
                                        variant="outlined"
                                        placeholder='0.00'
                                        value={formData.getAmount}
                                        onChange={handleChange}
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                fontWeight: '400', // Regular font weight for input text
                                            },
                                            '& .MuiOutlinedInput-root': {
                                                '&:hover fieldset': {
                                                    borderColor: 'primary.main', // Highlight on hover
                                                },
                                            },
                                        }}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">{formData.toCurrency || ''}</InputAdornment>,
                                        }}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <InputLabel htmlFor="toCurrency">To Currency</InputLabel>
                                    <Select
                                        fullWidth
                                        label="Currency"
                                        name="toCurrency"
                                        value={formData.toCurrency}
                                        onChange={(event: any) => handleChange(event)}
                                        variant="outlined"
                                        disabled={isCheckout}
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                fontWeight: '400', // Regular font weight for input text
                                            },
                                            '& .MuiOutlinedInput-root': {
                                                '&:hover fieldset': {
                                                    borderColor: 'primary.main', // Highlight on hover
                                                },
                                            },
                                        }}
                                    >
                                        {renderCurrencyOptions()}
                                    </Select>
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabel htmlFor="wallet">Receipient Wallet</InputLabel>
                                    <TextField
                                        type="text"
                                        fullWidth
                                        name="wallet"
                                        variant="outlined"
                                        placeholder='Please enter your wallet address'
                                        value={formData.wallet}
                                        onChange={handleChange}
                                        disabled={isCheckout}
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                fontWeight: '400', // Regular font weight for input text
                                            },
                                            '& .MuiOutlinedInput-root': {
                                                '&:hover fieldset': {
                                                    borderColor: 'primary.main', // Highlight on hover
                                                },
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} justifyContent='center' display='flex'>
                                    {/* <Button onClick={() => checkout()} disabled={isCheckout} startIcon={isCheckout ? <Iconify size='25px' icon='solar:check-read-outline' /> : <></>} variant='contained' >Checkout</Button> */}
                                    <Button
                                        variant="contained"
                                        sx={{
                                            backgroundColor: 'primary.main', // Use your theme's primary color
                                            color: 'common.white',
                                            fontWeight: 'bold', // Makes text bold
                                            fontSize: '1rem', // Appropriate font size
                                            letterSpacing: '0.05rem', // Spacing between letters for better readability
                                            py: 1, // Padding top and bottom
                                            px: 2.5, // Padding left and right
                                            boxShadow: '0 3px 5px 2px rgba(0, 105, 217, .3)', // Subtle shadow for depth
                                            '&:hover': {
                                                backgroundColor: 'primary.dark', // Darker shade of the primary color on hover
                                                // Reduce the shadow on hover
                                                boxShadow: '0 2px 4px 1px rgba(0, 105, 217, .25)',
                                            },
                                            '&:disabled': {
                                                backgroundColor: 'action.disabledBackground', // Disabled state background
                                                color: 'action.disabled', // Disabled state text color
                                            },
                                            borderRadius: '25px', // Rounded corners for a modern look
                                            transition: 'background-color 0.3s, box-shadow 0.3s', // Smooth transition for background and shadow
                                        }}
                                        disabled={isCheckout} // Assuming `isCheckout` is your disabling condition
                                        onClick={() => checkout()}
                                        startIcon={isCheckout ? <Iconify size='25px' icon='solar:check-read-outline' /> : <></>}
                                    >
                                        Checkout
                                    </Button>
                                </Grid>

                            </Grid>
                            <Grid display={isCheckout ? '' : 'none'} container spacing={2} marginTop={1} >
                                <Grid item xs={12} display='flex' width='100%' justifyContent='center'>
                                    <Typography sx={{ width: '80%' }}>
                                        {/* <Card sx={{ padding: 2 }}> */}
                                        <Card sx={{ padding: 2, overflow: 'hidden', borderRadius: '12px', boxShadow: '1px 1px 20px rgba(0,0,0,0.1)' }}>
                                            <Typography component='div' variant='h6' >Payment information</Typography>
                                            <Grid container spacing={2}>
                                                <Grid item xs={6}>
                                                    <Box sx={{
                                                        padding: 2,
                                                        backgroundColor: 'background.default', // Slightly lighter or darker depending on your theme
                                                        boxShadow: 1,
                                                        borderRadius: 2,
                                                        border: '1px solid',
                                                        borderColor: 'divider'  // Adds a subtle border based on the theme's divider color
                                                    }}>
                                                        <Typography component='div' variant='subtitle1' sx={{ marginBottom: 1, fontWeight: 'fontWeightMedium' }}>Bank name: {selectedAccount?.title}</Typography>
                                                        <Typography component='div' variant='subtitle1' sx={{ marginBottom: 1 }}>Account No.: {selectedAccount?.no}</Typography>
                                                        <Typography component='div' variant='subtitle1' sx={{ marginBottom: 1 }}>Account name: {selectedAccount?.name}</Typography>
                                                        <Typography component='div' variant='subtitle1'>Amount: {formatCurrency(formData.sendAmount)} {formData.fromCurrency}</Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Box sx={{
                                                        padding: 1,
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        height: '100%',
                                                        backgroundColor: 'background.paper', // Maintains the primary background for contrast
                                                        boxShadow: 'none' // Optionally remove the shadow here for a flatter design
                                                    }}>
                                                        <Box sx={{
                                                            maxWidth: '100%',
                                                            maxHeight: 164,
                                                            borderRadius: 2,
                                                            overflow: 'hidden',
                                                            border: '1px solid',
                                                            borderColor: 'divider' // Matches the border of the text box for consistency
                                                        }}>
                                                            <img
                                                                style={{ width: '100%', height: 'auto', display: 'block' }}
                                                                src={`${selectedAccount?.image}?w=164&h=164&fit=crop&auto=format`}
                                                                alt={selectedAccount?.name}
                                                                loading="lazy"
                                                            />
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                            </Grid>


                                        </Card>
                                    </Typography>

                                </Grid>
                            </Grid>
                            <Grid display={isCheckout ? '' : 'none'} container spacing={2} marginTop={1} >
                                <Grid item xs={6}>
                                    <InputLabel htmlFor="bankName">Bank name</InputLabel>
                                    <TextField
                                        type="text"
                                        fullWidth
                                        name="bankName"
                                        variant="outlined"
                                        placeholder='Please enter your bank name'
                                        value={formData.bankName}
                                        onChange={handleChange}
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                fontWeight: '400', // Regular font weight for input text
                                            },
                                            '& .MuiOutlinedInput-root': {
                                                '&:hover fieldset': {
                                                    borderColor: 'primary.main', // Highlight on hover
                                                },
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabel htmlFor="accountNo">Account no.</InputLabel>
                                    <TextField
                                        type="text"
                                        fullWidth
                                        name="accountNo"
                                        variant="outlined"
                                        placeholder='Please enter your account number'
                                        value={formData.accountNo}
                                        onChange={handleChange}
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                fontWeight: '400', // Regular font weight for input text
                                            },
                                            '& .MuiOutlinedInput-root': {
                                                '&:hover fieldset': {
                                                    borderColor: 'primary.main', // Highlight on hover
                                                },
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabel htmlFor="accountName">Account name</InputLabel>
                                    <TextField
                                        type="text"
                                        fullWidth
                                        name="accountName"
                                        variant="outlined"
                                        placeholder='Please enter your account name'
                                        value={formData.accountName}
                                        onChange={handleChange}
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                fontWeight: '400', // Regular font weight for input text
                                            },
                                            '& .MuiOutlinedInput-root': {
                                                '&:hover fieldset': {
                                                    borderColor: 'primary.main', // Highlight on hover
                                                },
                                            },
                                        }}
                                    />
                                </Grid>
                                {file && <Grid item xs={12}>
                                    <InputLabel htmlFor="imageUpload">Uploaded Image</InputLabel>
                                    <Box
                                        sx={{
                                            border: '1px solid #ddd',
                                            padding: '10px',
                                            borderRadius: '4px',
                                            mt: 2,
                                        }}
                                    >
                                        <img src={file.url} alt="Preview" style={{ width: '100%', maxHeight: '400px', objectFit: 'contain' }} />
                                    </Box>
                                    <Typography variant="subtitle1" sx={{ mt: 2 }}>
                                        Filename: {file.name}
                                    </Typography>
                                </Grid>}
                                <Grid item xs={12}>
                                    <InputLabel htmlFor="transactionTime">Transaction Time</InputLabel>
                                    <TextField
                                        type="datetime-local"
                                        fullWidth
                                        name="transactionTime"
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }} // Ensure the label doesn't overlap with the selected value
                                        value={formData.transactionTime}
                                        onChange={handleChange}
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                fontWeight: '400', // Regular font weight for input text
                                            },
                                            '& .MuiOutlinedInput-root': {
                                                '&:hover fieldset': {
                                                    borderColor: 'primary.main', // Highlight on hover
                                                },
                                            },
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} spacing={2} justifyContent='end' display='flex'>
                                    <input
                                        accept="image/*"
                                        style={{ opacity: 0, position: 'absolute', left: '-9999px' }}
                                        id="file-upload"
                                        type="file"
                                        onChange={handleFileChange}
                                    />
                                    <label htmlFor="file-upload">
                                        <Button
                                            size='large'
                                            variant="outlined"
                                            component="span"
                                            sx={{
                                                backgroundColor: 'primary.main', // Use your theme's primary color
                                                color: 'common.white',
                                                fontWeight: 'bold', // Makes text bold
                                                fontSize: '1rem', // Appropriate font size
                                                letterSpacing: '0.05rem', // Spacing between letters for better readability
                                                py: 1, // Padding top and bottom
                                                px: 2.5, // Padding left and right
                                                boxShadow: '0 3px 5px 2px rgba(0, 105, 217, .3)', // Subtle shadow for depth
                                                '&:hover': {
                                                    backgroundColor: 'primary.dark', // Darker shade of the primary color on hover
                                                    // Reduce the shadow on hover
                                                    boxShadow: '0 2px 4px 1px rgba(0, 105, 217, .25)',
                                                },
                                                '&:disabled': {
                                                    backgroundColor: 'action.disabledBackground', // Disabled state background
                                                    color: 'action.disabled', // Disabled state text color
                                                },
                                                borderRadius: '25px', // Rounded corners for a modern look
                                                transition: 'background-color 0.3s, box-shadow 0.3s', // Smooth transition for background and shadow
                                            }}
                                        >
                                            {!file ? `Select file` : `Change file`}
                                        </Button>
                                    </label>
                                    <Box sx={{ width: '10px' }} />
                                    <Button disabled={!file} onClick={() => handleSubmit()} startIcon={<Iconify icon='solar:check-read-outline' />} variant='contained'
                                        sx={{
                                            backgroundColor: 'primary.main', // Use your theme's primary color
                                            color: 'common.white',
                                            fontWeight: 'bold', // Makes text bold
                                            fontSize: '1rem', // Appropriate font size
                                            letterSpacing: '0.05rem', // Spacing between letters for better readability
                                            py: 1, // Padding top and bottom
                                            px: 2.5, // Padding left and right
                                            boxShadow: '0 3px 5px 2px rgba(0, 105, 217, .3)', // Subtle shadow for depth
                                            '&:hover': {
                                                backgroundColor: 'primary.dark', // Darker shade of the primary color on hover
                                                // Reduce the shadow on hover
                                                boxShadow: '0 2px 4px 1px rgba(0, 105, 217, .25)',
                                            },
                                            '&:disabled': {
                                                backgroundColor: 'action.disabledBackground', // Disabled state background
                                                color: 'action.disabled', // Disabled state text color
                                            },
                                            borderRadius: '25px', // Rounded corners for a modern look
                                            transition: 'background-color 0.3s, box-shadow 0.3s', // Smooth transition for background and shadow
                                        }}
                                    >Confirm</Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </CardContent>

                </Card>
            </Typography>
            <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
                <DialogTitle>{"Invalid Input"}</DialogTitle>
                <DialogContent>
                    <Typography>Invalid input. please fill details next step.</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Ok</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={isSuccessDialogOpen}
                onClose={() => setIsSuccessDialogOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Transaction Created Successfully"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Thank you! Your transaction has been successfully created.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setIsSuccessDialogOpen(false)
                        setLoading(true)
                        setTimeout(() => {
                            setLoading(false)
                            window.location.reload()
                        }, 2000);
                        
                    }} autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default BuyCrypto