import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  InputLabel,
  Typography,
  TextField,
  MenuItem,
  Select,
  ListItemIcon,
  InputAdornment,
  CardHeader,
} from "@mui/material";
import "../App.css";
import Iconify from "../helpers/Iconify";
import { fetchCurrencyData } from "../utils/CurrencyUtils";
import { Autocomplete } from "@mui/material";
import ApiExchangeRates from "../services/ApiExchangeRates";
import Loading from "../components/Loading";
import { formatCurrency, formatDate } from "../helpers/tools";
import { useNavigate } from "react-router-dom";
import WeButton from "../components/WeButton";
import CryptoChart from "../components/CryptoChart";
import LiveCoinWatchWidget from "../components/LiveCoinWatchWidget";
const Converter = () => {
  const navigate = useNavigate();
  const apiExchangeRates = new ApiExchangeRates();
  const [loading, setLoading] = useState(false);
  const [resultRate, setResultRate] = useState<any>(null);
  const [result, setResult] = useState<any>(null);
  const [formData, setFormData] = useState({
    amount: "",
    from: "THB",
    to: "USDT",
  });
  const [currencyData, setCurrencyData] = useState([
    {
      currency: "USDT",
      name: "Tether",
      region: "North America",
      country: "United States",
      flag: "simple-icons:tether",
    },
    // {
    //   currency: "CAD",
    //   name: "Canadian Dollar",
    //   region: "North America",
    //   country: "Canada",
    //   flag: "emojione-v1:flag-for-canada",
    // },
    // {
    //   currency: "MXN",
    //   name: "Mexican Peso",
    //   region: "North America",
    //   country: "Mexico",
    //   flag: "emojione-v1:flag-for-mexico",
    // },
    // {
    //   currency: "EUR",
    //   name: "Euro",
    //   region: "Europe",
    //   country: null,
    //   flag: "twemoji:flag-european-union",
    // },
    // {
    //   currency: "GBP",
    //   name: "British Pound Sterling",
    //   region: "Europe",
    //   country: "United Kingdom",
    //   flag: "emojione-v1:flag-for-united-kingdom",
    // },
    // {
    //   currency: "CHF",
    //   name: "Swiss Franc",
    //   region: "Europe",
    //   country: "Switzerland",
    //   flag: "emojione-v1:flag-for-switzerland",
    // },
    // {
    //   currency: "SEK",
    //   name: "Swedish Krona",
    //   region: "Europe",
    //   country: "Sweden",
    //   flag: "emojione-v1:flag-for-sweden",
    // },
    // {
    //   currency: "NOK",
    //   name: "Norwegian Krone",
    //   region: "Europe",
    //   country: "Norway",
    //   flag: "emojione-v1:flag-for-norway",
    // },
    // {
    //   currency: "DKK",
    //   name: "Danish Krone",
    //   region: "Europe",
    //   country: "Denmark",
    //   flag: "emojione-v1:flag-for-denmark",
    // },
    // {
    //   currency: "HUF",
    //   name: "Hungarian Forint",
    //   region: "Europe",
    //   country: "Hungary",
    //   flag: "emojione-v1:flag-for-hungary",
    // },
    // {
    //   currency: "PLN",
    //   name: "Polish Złoty",
    //   region: "Europe",
    //   country: "Poland",
    //   flag: "emojione-v1:flag-for-poland",
    // },
    // {
    //   currency: "HRK",
    //   name: "Croatian Kuna",
    //   region: "Europe",
    //   country: "Croatia",
    //   flag: "emojione-v1:flag-for-croatia",
    // },
    // {
    //   currency: "BGN",
    //   name: "Bulgarian Lev",
    //   region: "Europe",
    //   country: "Bulgaria",
    //   flag: "emojione-v1:flag-for-bulgaria",
    // },
    // {
    //   currency: "RON",
    //   name: "Romanian Leu",
    //   region: "Europe",
    //   country: "Romania",
    //   flag: "emojione-v1:flag-for-romania",
    // },
    // {
    //   currency: "RUB",
    //   name: "Russian Ruble",
    //   region: "Europe",
    //   country: "Russia",
    //   flag: "emojione-v1:flag-for-russia",
    // },
    // {
    //   currency: "TRY",
    //   name: "Turkish Lira",
    //   region: "Europe",
    //   country: "Turkey",
    //   flag: "emojione-v1:flag-for-turkey",
    // },
    // {
    //   currency: "UAH",
    //   name: "Ukrainian Hryvnia",
    //   region: "Europe",
    //   country: "Ukraine",
    //   flag: "emojione-v1:flag-for-ukraine",
    // },
    // {
    //   currency: "ISK",
    //   name: "Icelandic Króna",
    //   region: "Europe",
    //   country: "Iceland",
    //   flag: "emojione-v1:flag-for-iceland",
    // },
    // {
    //   currency: "AED",
    //   name: "United Arab Emirates Dirham",
    //   region: "Middle East",
    //   country: "United Arab Emirates",
    //   flag: "emojione-v1:flag-for-united-arab-emirates",
    // },
    // {
    //   currency: "QAR",
    //   name: "Qatari Riyal",
    //   region: "Middle East",
    //   country: "Qatar",
    //   flag: "emojione-v1:flag-for-qatar",
    // },
    // {
    //   currency: "KWD",
    //   name: "Kuwaiti Dinar",
    //   region: "Middle East",
    //   country: "Kuwait",
    //   flag: "emojione-v1:flag-for-kuwait",
    // },
    // {
    //   currency: "OMR",
    //   name: "Omani Rial",
    //   region: "Middle East",
    //   country: "Oman",
    //   flag: "emojione-v1:flag-for-oman",
    // },
    // {
    //   currency: "BHD",
    //   name: "Bahraini Dinar",
    //   region: "Middle East",
    //   country: "Bahrain",
    //   flag: "emojione-v1:flag-for-bahrain",
    // },
    // {
    //   currency: "JPY",
    //   name: "Japanese Yen",
    //   region: "Asia",
    //   country: "Japan",
    //   flag: "emojione-v1:flag-for-japan",
    // },
    // {
    //   currency: "CNY",
    //   name: "Chinese Yuan",
    //   region: "Asia",
    //   country: "China",
    //   flag: "emojione-v1:flag-for-china",
    // },
    // {
    //   currency: "INR",
    //   name: "Indian Rupee",
    //   region: "Asia",
    //   country: "India",
    //   flag: "emojione-v1:flag-for-india",
    // },
    {
      currency: "THB",
      name: "Thai Baht",
      region: "Asia",
      country: "Thailand",
      flag: "emojione-v1:flag-for-thailand",
    },
    // {
    //   currency: "IDR",
    //   name: "Indonesian Rupiah",
    //   region: "Asia",
    //   country: "Indonesia",
    //   flag: "emojione-v1:flag-for-indonesia",
    // },
    // {
    //   currency: "MYR",
    //   name: "Malaysian Ringgit",
    //   region: "Asia",
    //   country: "Malaysia",
    //   flag: "emojione-v1:flag-for-malaysia",
    // },
    // {
    //   currency: "PHP",
    //   name: "Philippine Peso",
    //   region: "Asia",
    //   country: "Philippines",
    //   flag: "emojione-v1:flag-for-philippines",
    // },
    // {
    //   currency: "VND",
    //   name: "Vietnamese Đồng",
    //   region: "Asia",
    //   country: "Vietnam",
    //   flag: "emojione-v1:flag-for-vietnam",
    // },
    // {
    //   currency: "PKR",
    //   name: "Pakistani Rupee",
    //   region: "Asia",
    //   country: "Pakistan",
    //   flag: "emojione-v1:flag-for-pakistan",
    // },
    {
      currency: "KRW",
      name: "South Korean Won",
      region: "Asia",
      country: "South Korea",
      flag: "emojione-v1:flag-for-south-korea",
    },
  ]);
  useEffect(() => {
    if (resultRate && formData.amount) {
      calculation(formData.amount);
    }
  }, [resultRate]);

  const calculation = (amount?: string) => {
    setResult(parseFloat(amount || "1") * (resultRate?.conversion_rate || 1));
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const data = await fetchCurrencyData();
  //     setCurrencyData(data);
  //   };

  //   fetchData();
  // }, []);

  const renderCurrencyOptions = () => {
    return currencyData.map((currency: any) => (
      <MenuItem
        key={currency.currency}
        value={currency.currency}
        disabled={
          formData.from == currency.currency || formData.to == currency.currency
        }
      >
        <ListItemIcon>
          <Iconify icon={currency.flag} />
        </ListItemIcon>
        {currency.currency} - {currency.name}
      </MenuItem>
    ));
  };

  const handleChange = (e: any) => {
    const target = e.target as HTMLInputElement;
    setFormData({ ...formData, [target.name || ""]: target.value });
    if (target.name == "from" || target.name == "to") {
      setResultRate(null);
      setResult(null);
    } else {
      if (resultRate) {
        calculation(target.value);
      }
    }
  };

  // useEffect(() => {

  // }, [formData])

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Add your form submission logic here
    if (formData.from && formData.to && formData.amount) {
      getRate(formData.from, formData.to);
    }
  };
  const onSwitch = () => {
    const currentFrom = formData.from;
    const currentTo = formData.to;
    setFormData({ ...formData, from: currentTo, to: currentFrom });
    setResultRate(null);
    setResult(null);
  };

  const getRate = (base: string, to: string) => {
    setLoading(true);
    apiExchangeRates
      .pairExchangeRate(base == 'USDT' ? 'USD' : base, to == 'USDT' ? 'USD' : to)
      .then((response) => {
        if (response && response.result == "success") {
          setResultRate(response);
        } else {
          setResultRate(null);
        }
        setLoading(false);
      })
      .catch(() => {
        setResultRate(null);
        setLoading(false);
      });
  };

  return (
    <div>
      <Loading loading={loading} />
      <Card
        sx={{
          width: "820px",
          mx: "auto", // Centers the card with margin auto
          overflow: "hidden",
          borderRadius: "12px",
          boxShadow: "0 4px 24px rgba(0,0,0,0.12)",
          p: 3, // Padding inside the card
        }}
      >
        <CardContent>
          <Typography>
            {/* <Iconify icon='bi:currency-exchange' sx={{ marginRight: '8px', color: 'primary.main' }} /> */}
            <div className="text-green fb">EXCANGE RATE</div>
            <div className="text-purple">อัตราแลกเปลี่ยนที่ดีที่สุด</div>
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2} sx={{ marginTop: 1 }}>
              <Grid item xs={3}>
                <InputLabel htmlFor="amount">Amount</InputLabel>
                <TextField
                  type="number"
                  fullWidth
                  name="amount"
                  variant="outlined"
                  placeholder="0.00"
                  value={formData.amount}
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {formData.from || ""}
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "primary.main",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <InputLabel htmlFor="from">From</InputLabel>
                <Select
                  fullWidth
                  label="From"
                  name="from"
                  value={formData.from}
                  onChange={(event) => handleChange(event)}
                  variant="outlined"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "primary.main",
                      },
                    },
                  }}
                >
                  {renderCurrencyOptions()}
                </Select>
              </Grid>
              <Grid
                item
                xs={1}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <IconButton
                  onClick={() => onSwitch()}
                  sx={{ color: "action.active" }}
                >
                  <Iconify icon="icon-park-outline:switch" />
                </IconButton>
              </Grid>
              <Grid item xs={4}>
                <InputLabel htmlFor="to">To</InputLabel>
                <Select
                  fullWidth
                  label="To"
                  name="to"
                  value={formData.to}
                  onChange={(event) => handleChange(event)}
                  variant="outlined"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "primary.main",
                      },
                    },
                  }}
                >
                  {renderCurrencyOptions()}
                </Select>
              </Grid>
            </Grid>
            <Typography sx={{ textAlign: "center", marginTop: 4 }}>
              {/* <Button size='large' startIcon={<Iconify icon='material-symbols-light:next-week-outline' />} type="submit" variant="contained" color="primary" disabled={!formData.amount || !formData.from || !formData.to} sx={{ boxShadow: 2 }}>
                Convert
              </Button> */}
              <WeButton type="submit">check rate now !</WeButton>
            </Typography>
            {result && (
              <Typography component="div" sx={{ marginTop: 2 }}>
                <Typography component="div" variant="h5">
                  {formData.amount || 1}{" "}
                  {currencyData.find((c) => c.currency === formData.from)?.name}{" "}
                  =
                </Typography>
                <Typography
                  component="div"
                  variant="h3"
                  sx={{ fontWeight: "bold" }}
                >
                  {formatCurrency(result, 6)}{" "}
                  {currencyData.find((c) => c.currency === formData.to)?.name}
                </Typography>
                <Typography component="div" variant="h6">
                  {formData.amount || 1} {formData.from} ={" "}
                  {resultRate?.conversion_rate || 1} {formData.to}
                </Typography>
                <Typography component="span">
                  Last updated {formatDate(resultRate?.time_last_update_utc)}
                </Typography>
                <div className="text-center mt-1">
                  <WeButton
                    onClick={() => navigate("/buycrypto")}
                    // fullWidth
                    // variant="contained"
                    // color="info"
                    // sx={{ marginTop: 2, boxShadow: 2 }}
                  >
                    Buy now
                  </WeButton>
                </div>
              </Typography>
            )}
          </form>
        </CardContent>
        <div className="text-center">
          <h1 className="text-purple fb">ซื้อUSDTผ่านการแลกเปลี่ยนแบบ P2P</h1>
          <div style={{display:'flex',justifyContent:'center'}}>
            <h4 className="text-green" style={{ width: "60%" }}>
              แปลงสกุลเงิน THAI BATH ของคุณเป็น USDT
              โดยตรงจากผู้ขายกันอย่างปลอดภัย ภายใต้แพลตฟอร์ม WETRUST เพื่อซื้อ
              USDT จากผู้ค้าภายในภูมิภาคท้องถิ่นของคุณ
            </h4>
          </div>
        </div>
        <div className="text-center mt-2">
          <Button variant="outlined">REGISTER NOW</Button>
          {/* <WeButton>Register now</WeButton> */}
        </div>
        <div>{/* <CryptoChart symbol="BINANCE:USDTUSD" /> */}</div>
        <div className="text-center mt-2">
          <div style={{ display: "inline-flex" }} className="fb">
            ซื้อ{" "}
            <div style={{ color: "#00BF63" }}>
              {}USDT{}
            </div>{" "}
            กับ <div style={{ color: "#5271FF" }}>WETRUST</div> ดีอย่างไร
          </div>
          <div>
            ทุกวันนี้ มองไปทางไหนก็เจอแต่แพลตฟอร์มคริปโต แต่จะมีสักกี่แพลตฟอร์ม
            ที่คุณสามารถซื้อ USDT
            โดยตรงกับผู้ขายท้องถิ่นในภูมิภาคด้วยราคาที่เหมาะสมและปลอดภัยได้
          </div>
        </div>
        <div>
          chart
          {/* <LiveCoinWatchWidget/> */}
        </div>
        <div>
          <Grid container spacing={2} sx={{ marginTop: 1 }}>
            <Grid item xs={4}>
              <Card
                className="card-purple"
                style={{ backgroundColor: "#EFECFF" }}
              >
                <h2 className="fb text-purple">
                  ทำไมต้องซื้อ USDT กับ WETRUST{" "}
                </h2>
                <h5 className="text-purple-light">
                  WETRUST
                  นำเสนออินเตอร์เฟซที่ใช้งานง่ายทั้งบนมือถือและเดสก์ท็อปสำหรับการทำธุรกรรมที่รวดเร็วจากผู้ขายที่เราคัดมาแล้วและปลอดภัย
                  เพียงไม่กี่คลิกเท่านั้นที่จะซื้อ
                  โดยการชำระเงินผ่านช่องทางที่สะดวกและรวดเร็ว
                </h5>
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card
                className="card-purple"
                style={{ backgroundColor: "#EFECFF" }}
              >
                <h2 className="fb text-purple">
                  EAZY TO PAY รองรับระบบชำระเงินชั้นนำในภูมิภาค
                </h2>
                <h5 className="text-purple-light">
                  WETRUST รองรับทุกธนาคารในภูมิภาคของคุณ รวมถึงแพลตฟอร์มPAYMENT
                  ท้องถิ่น เช่น TRUE MONEY WALLET ตลอดจน PROMTPAY
                  เพื่อความสะดวกและความแตกต่างหลากหลายในระบบชำระเงิน
                </h5>
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card
                className="card-purple"
                style={{ backgroundColor: "#EFECFF" }}
              >
                <h2 className="fb text-purple">
                  RATE NOW ! อัพเดตข่าวสารและราคาล่าสุด
                </h2>
                <h5 className="text-purple-light">
                  WETRUST
                  เชื่อมต่อรับข้อมูลตลาดแบบเรียลไทม์เพื่อตรวจสอบสภาพคล่องของตลาดการแข่งขัน
                  คุณสามารถเช็คเรทราคาขายเพียงปลายนิ้วสัมผัส
                  ทำให้คุณสามารถเข้าถึง USDT ได้อย่างมีประสิทธิภาพ
                </h5>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card
                className="card-purple"
                style={{ backgroundColor: "#EFECFF" }}
              >
                <Grid container>
                  <Grid item xs={10}>
                    <h2 className="fb text-purple">
                      ปลอดภัยและมีความน่าเชื่อถือสูงที่สุด
                    </h2>
                    <h5 className="text-purple-light">
                      WETRUST เราคัดเลือกร้านค้าในภูมิภาคที่มีประวัติขาย USDT
                      มาอย่างยาวนานขาวสะอาดและมีตัวตนอยู่จริงมาอยู่ใน WETRUST
                      เรามีความพร้อมให้บริการสูงและมีสภาพคล่องการันตรีการป้องกันการทุจริต
                      ได้อย่าง 100 % มีระบบ SUPPORT ที่สมาชิกสามารถติดต่อได้ 24
                      ชั่วโมง คุณจริงปลอดภัยอย่างแท้จริงในการทำธุรกรรมกับ
                      WETRUST
                    </h5>
                  </Grid>
                  <Grid item xs={2}>
                    <div className="text-center mt-1">
                      <img
                        color="#fff"
                        style={{ width: "100%" }}
                        src="/icons/icon-park-solid--protect.svg"
                        alt=""
                      />
                    </div>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <h3>
                รองรับการซื้อและขาย <span className="text-green">USDT</span>{" "}
                ผ่านระบบ{" "}
                <span className="text-purple">WETRUST API CONNECT</span>
                กับคู่ค้าภายนอกเพื่อขยายโอกาสทางธุรกิจ
              </h3>

              <div className="text-center">
                <img
                  color="#fff"
                  style={{ width: "200px" }}
                  src="/icons/api-interface-svgrepo-com.svg"
                  alt=""
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <Card
                className="card-purple"
                style={{ backgroundColor: "#EFECFF" }}
              >
                <h2 className="fb text-purple">
                  พร้อมสำหรับทุกธุรกิจและทุกโอกาสที่ต้องการ การชำระจากเงิน THB
                  เป็น USDT เรียลทาม!
                </h2>
                <h5 className="text-purple-light">
                  WETRUST เราคือผู้ให้บริการชำระเงิน จากเงิน THB เป็น uSDT
                  เราสามารถยกร้านค้า USDT ไปตั้งอยู่บนแพลตฟอร์มของคุณได้
                  พร้อมระบบการชำระเงินที่เข้าใจง่าย ไม่ยุ่งยาก
                  เพียงกรอกข้อมูลเพื่อสร้างลิงค์
                  ก่อนส่งลิงค์ให้ลูกค้ากดชำระค่าสินค้าเท่านั้น
                  และระบบจะทำการแลกเปลี่ยน เป็น USDT โดยระบบWETRUST API TO
                  CONNECT ทำให้ทุกการซื้อขาย USDT ไม่มีสะดุด
                  เมื่อทำรายการเสร็จระบบจะบันทึกข้อมูลทั้งหมดลงระบบหลังบ้านทันที
                  เพื่อให้คุณสามารถวางแผนขยายยอดขายสินค้าหรือสินค้าของคุณได้อย่างง่ายดาย
                  ไม่มีสะดุด
                </h5>
              </Card>
            </Grid>
            <Grid item xs={2.4}>
              <Card
                style={{
                  padding: 10,
                  backgroundColor: "#5271FF",
                  color: "#fff",
                }}
              >
                <div className="text-center mt-1">
                  <img
                    color="#fff"
                    style={{ width: "50%" }}
                    src="/icons/mdi-qrcode-scan.svg"
                    alt=""
                  />
                </div>
                <h4>API to generate QR CODE</h4>
                <h6>QR CODE payment system</h6>
              </Card>
            </Grid>
            <Grid item xs={2.4}>
              <Card
                style={{
                  padding: 10,
                  backgroundColor: "#5271FF",
                  color: "#fff",
                }}
              >
                <div className="text-center mt-1">
                  <img
                    color="#fff"
                    style={{ width: "50%" }}
                    src="/icons/game-icons-cash.svg"
                    alt=""
                  />
                </div>
                <h4>LOCAL BANK transfer</h4>
                <h6>Support all banks in Thailand</h6>
              </Card>
            </Grid>
            <Grid item xs={2.4}>
              <Card
                style={{
                  padding: 10,
                  backgroundColor: "#5271FF",
                  color: "#fff",
                }}
              >
                <div className="text-center mt-1">
                  <img
                    color="#fff"
                    style={{ width: "50%" }}
                    src="/icons/truemoney.svg"
                    alt=""
                  />
                </div>
                <h4>TRUE MONEY WALLET</h4>
                <h6>This is the most popular E-wallet in Thailand.</h6>
              </Card>
            </Grid>
            <Grid item xs={2.4}>
              <Card
                style={{
                  padding: 10,
                  backgroundColor: "#5271FF",
                  color: "#fff",
                }}
              >
                <div className="text-center mt-1">
                  <img
                    color="#fff"
                    style={{ width: "50%" }}
                    src="/icons/circum-barcode.svg"
                    alt=""
                  />
                </div>
                <h4>API to generate Barcode</h4>
                <h6>Bill payment system.</h6>
              </Card>
            </Grid>
            <Grid item xs={2.4}>
              <Card
                style={{
                  padding: 10,
                  backgroundColor: "#5271FF",
                  color: "#fff",
                }}
              >
                <div className="text-center mt-1">
                  <img
                    color="#fff"
                    style={{ width: "50%" }}
                    src="/icons/mdi-approve.svg"
                    alt=""
                  />
                </div>
                <h4>FUND GUARANTEE</h4>
                <h6>We manage risk and guarantee your money.</h6>
              </Card>
            </Grid>
          </Grid>
        </div>
        <div className="text-center mt-1">
          <Button href="/contact" variant="outlined">
            CLICK เพื่อขอข้อมูลเพิ่มเติม!
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default Converter;
