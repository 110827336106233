// src/App.tsx
import React from 'react';
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import Routes from './Routes'; // Import the Routes component
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const theme = createTheme({
  palette: {
    primary: {
      main: '#123456', // A professional blue shade
    },
    secondary: {
      main: '#FFAB00', // A vibrant yellow for highlights and actions
    },
  },
  typography: {
    fontFamily: '"Barlow Condensed", Helvetica, Arial, sans-serif',
    h6: {
      fontWeight: 500,
    },
  },
  shape: {
    borderRadius: 8, // Smoother edges
  },
});


const App: React.FC = () => {

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes /> {/* Render the Routes component */}
      <ToastContainer/>
    </ThemeProvider>
  );
};

export default App;
