import BaseExchangeApiService from "./BaseExchangeApiService";

class ApiExchangeRates {
    private readonly apiKey: string;
    private readonly baseUrl: string;

    constructor() {
        this.apiKey = '5c1b24f268212bde99ea5103'
        this.baseUrl = 'https://v6.exchangerate-api.com/v6'
    }

    pairExchangeRate(baseCurrency: string, toCurrency: string): Promise<any> {
        const url = `${this.baseUrl}/${this.apiKey}/pair/${baseCurrency}/${toCurrency}`;
        return BaseExchangeApiService.get(url)
            .then((response) => response.data)
            .catch((error) => {
                throw error;
            });
    }
}

export default ApiExchangeRates;
